import { Injectable } from '@angular/core';

import { of, tap } from 'rxjs';

import { LiveArea, Property } from '@alan-apps/data-access';

import { liveAreaWindowPassKey, propertyWindowPassKey } from './print-tmp-keys';

@Injectable({
  providedIn: 'root',
})
export class PrintService {
  getProperty() {
    return this.getWindowData(propertyWindowPassKey);
  }

  setProperty(newWindow: Window, property: Property) {
    if (!newWindow) {
      console.error('newWindow is null');

      return;
    }

    newWindow[propertyWindowPassKey] = property;
  }

  getLiveArea() {
    return this.getWindowData(liveAreaWindowPassKey);
  }

  setLiveArea(newWindow: Window, liveArea: LiveArea) {
    newWindow[liveAreaWindowPassKey] = liveArea;
  }

  private getWindowData(key: string) {
    const sourceData = window[key];
    if (sourceData) {
      return of(sourceData).pipe(
        tap(() => {
          delete window[key];
        }),
      );
    }
    return false;
  }
}
