import { Location } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { AbstractControl } from '@angular/forms';

import { BehaviorSubject, Subject, tap } from 'rxjs';

import { propertyTypes } from '@alan-apps/api-interfaces';
import {
  GetPropertyGQL,
  GetPropertyPageGQL,
  Property,
  PropertySearchInput,
} from '@alan-apps/data-access';
import { clearObjToForm, urlJoin } from '@alan-apps/utils';
import {
  BaseHttpService,
  BaseService,
  cache,
  ICache,
  useI18nRouter,
} from '@nghedgehog/angular-ui';

import { SaveQuery } from './state-condition.handler';
import { PrintService } from './print/print.service';

@Injectable({
  providedIn: 'root',
})
export class PropertyService implements ICache {
  private _http = inject(BaseHttpService);
  private _location = inject(Location);
  private getPropertyGQL = inject(GetPropertyGQL);
  private getPropertyPageGQL = inject(GetPropertyPageGQL);
  private _base = inject(BaseService);
  private _print = inject(PrintService);
  readonly storageKey = 'PropertyService';

  deleteSaveQuery = new Subject<any | any[]>();
  deleteSaveAlias = new Subject<string>();

  choiceDistrictLength = new BehaviorSubject<number>(0);

  set districtLength(value) {
    this.choiceDistrictLength.next(this.choiceDistrictLength.value + value);
  }

  types = propertyTypes;

  private _router = useI18nRouter();

  search(query: any) {
    this._router.navigate(['/properties'], { queryParams: query });
  }

  searchNoRedirect(query: any) {
    const fromObject = clearObjToForm(query, true, (x) => `${x}`, false);

    const paramsString = new HttpParams({
      fromObject,
    }).toString();

    this._location.go(
      urlJoin(this._router.url.split('?').shift(), `?${paramsString}`),
    );

    return fromObject;
  }

  goMore(query: any) {
    this._router.navigate(['properties/page/1'], { queryParams: query });
  }

  get(id: number, isManage = false) {
    return this._http.apollo(this.getPropertyGQL.fetch({ id, isManage }));
  }

  list(option: PageOption, query: PropertySearchInput) {
    return this._http.apollo(
      this.getPropertyPageGQL.fetch({
        ...option,
        query,
      }),
    );
  }

  setValue(instance, target, targetName: string, choiceOne = false) {
    const queries = [];
    if (target.value === null && !target.checked) {
      instance[`${targetName}s`].forEach((x) => (x.checked = false));
      target.checked = true;
      instance[`${targetName}`].setValue(null);

      this.deleteSaveQuery.next(instance[`${targetName}s`]);
    } else {
      if (choiceOne) {
        instance[`${targetName}s`].forEach((x) => (x.checked = false));
        // target.checked = true;
        instance[`${targetName}`].setValue(null);
        this.deleteSaveQuery.next(instance[`${targetName}s`]);
      }

      instance[`${targetName}s`][0].checked = false;
      target.checked = !target.checked;

      if (target.checked) {
        queries.push(<SaveQuery>{
          data: target,
          key: 'title',
          delete: (data) => {
            data.checked = false;
          },
        });
      } else {
        this.deleteSaveQuery.next(target);
      }
      // update form value
      const value = instance[`${targetName}s`]
        .filter((x) => x.checked)
        .reduce((prev, curr) => {
          prev.push(curr.value);
          return prev;
        }, [])
        .join(',');
      instance[`${targetName}`].setValue(value || null);
    }
    return queries;
  }

  checkItemState({
    queries,
    getData,
    control,
    alias,
    dataHandler,
    deleteThen,
  }: CheckState) {
    let totalNum = 0;
    if (control?.value) {
      const arr = control.value.split(',');

      arr.forEach((sourceId) => {
        const item = getData.find(
          (d) => d.id === (dataHandler ? dataHandler(sourceId) : sourceId),
        );

        if (item) {
          item.checked = true;
          totalNum++;
          queries.push(<SaveQuery>{
            alias,
            data: item,
            key: 'name',
            delete: (data) => {
              data.checked = false;
              this.updateFormWithQueries(queries, control, alias);
              if (deleteThen) {
                deleteThen(data);
              }
            },
          });
        }
      });
    }
    return totalNum;
  }

  updateFormWithQueries(
    queries: SaveQuery[],
    formControl: AbstractControl,
    alisa: string,
  ) {
    const to: string = queries.reduce((prev, curr) => {
      if (curr.alias === alisa) {
        prev += `${curr.data.id},`;
      }
      return prev;
    }, '');
    formControl.patchValue(to.substring(0, to.length - 1) || null);
  }

  openPrint(id: number, property?: Property, params?: Record<string, string>) {
    return this._base
      .openUrl(this._router.getI18nUrl([`/print/properties`, `${id}`]), {
        specs: `left=20,top=20,width=1000,height=${
          window.screen.availHeight * 0.75
        }`,
        params: {
          print: 'true',
          ...params,
        },
      })
      .pipe(
        tap((newWindow) => {
          if (property && newWindow) {
            this._print.setProperty(newWindow, property);
          }
        }),
      );
  }

  @cache()
  getHomeCarouselList() {
    return this.list({ skip: 0, take: 5 }, { headline: true });
  }
}

export interface CheckState {
  queries: SaveQuery[];
  getData: any[];
  control: AbstractControl;
  alias: string;
  dataHandler?: (x) => any;
  deleteThen?: (x) => any;
}
